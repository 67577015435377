import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { SignupSuccess } from './TheNightlySignupSuccess'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

export const TheNightlySignupSuccessRegistration = createRegisterableComponent(
    'thenightly-signup-success',
    (_, services) => {
        return (
            <QueryClientProvider client={queryClient}>
                <SignupSuccess onEvent={services.onEvent} />
            </QueryClientProvider>
        )
    },
)
