import React from 'react'
import { EditionV4DTO } from '@west-australian-newspapers/publication-types'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { Editions } from './components/Editions'
import { HomepageEditions } from './components/HomepageEditions'
import { BaseClientConfig } from 'web-common'
import { TNNewsletterSignup } from '../../../banners/NewsletterSignup/TNNewsletterSignup'
import { RecaptchaProvider } from '../../../recaptcha/Recaptcha'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

export interface NightlyPreviousEditionsProps {
    editions: MaybeLoaded<EditionV4DTO[]>
    title: string
    type: 'homepage' | 'editions'
    config?: BaseClientConfig
    lazyloadImages?: boolean
    onEvent: (event: any) => void
}

export const NightlyPreviousEditions: React.FC<NightlyPreviousEditionsProps> =
    ({ editions, title, type, config, lazyloadImages, onEvent }) => {
        if (editions.loaded) {
            switch (type) {
                case 'editions':
                    return (
                        <Editions
                            editions={editions.result}
                            title={title}
                            lazyloadImages={lazyloadImages}
                            onEvent={onEvent}
                            interlacedComponent={
                                <>
                                    {config && config.recaptureSiteKey ? (
                                        <RecaptchaProvider
                                            siteKey={config.recaptureSiteKey}
                                        >
                                            <QueryClientProvider
                                                client={queryClient}
                                            >
                                                <TNNewsletterSignup
                                                    text={`Sign up to The Nightly's newsletters.`}
                                                    subText={
                                                        'Get the first look at the digital newspaper, curated daily stories and breaking headlines delivered to your inbox.'
                                                    }
                                                    buttonLabel={
                                                        'Get the Newsletter'
                                                    }
                                                    mode={'default'}
                                                    onEvent={() => {}}
                                                />
                                            </QueryClientProvider>
                                        </RecaptchaProvider>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            }
                        />
                    )
                case 'homepage':
                default:
                    return (
                        <HomepageEditions
                            editions={editions.result}
                            lazyloadImages={lazyloadImages}
                            title={title}
                        />
                    )
            }
        } else {
            return <></>
        }
    }
