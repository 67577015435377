import { TheNightlySection } from '@news-mono/common'
import { metrics, sectionThemeOverrider } from '@news-mono/component-library'
import {
    ElectionDefinition,
    getElectionId,
    GetRouteAdTargeting,
    PageType,
    TogglesReduxState,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'

type CreateElectionPageArgs = {
    electionDefinition: ElectionDefinition
    getAdTargeting: GetRouteAdTargeting
    toggles: TogglesReduxState
    heading?: string
    description?: string
}

export const createElectionLandingPage = ({
    heading,
    description,
    getAdTargeting,
    electionDefinition: { election, logo },
}: CreateElectionPageArgs): PageType<TheNightlySection> => {
    if (election.type === 'federal') {
        heading ??= `${election.year} Federal Election`
        description ??= `Latest Updates for the ${heading}`
    } else {
        heading ??= `${
            election.year
        } ${election.state.toUpperCase()} State Election`
        description ??= `Latest Updates for the ${heading}`
    }

    return {
        kind: 'page',
        heading,
        pageType: 'static',
        section: 'politics',
        hideHeading: true,
        adTargeting: getAdTargeting('home', 'default', 'home'),
        pageMeta: {
            description,
        },
        socialMeta: {
            description,
        },
        additionalPageProperties: {
            hasTopGutter: false,
        },
        compositions: [
            layout.composition({
                type: 'scheme-overrider',
                props: {
                    override: sectionThemeOverrider('thenightly', 'politics'),
                },
                contentAreas: {
                    children: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                containerWidth:
                                    metrics.thenightly.siteMetrics
                                        .mainContentWidth,
                                fullWidth: true,
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'election-navigation',
                                        props: {
                                            election,
                                            electionLogo: logo,
                                            activePage: 'updates',
                                            headingLevel: 'h1',
                                        },
                                    }),
                                    layout.component({
                                        type: 'election-seat-widget',
                                        props: {
                                            electoratePageUrl: `/politics/${getElectionId(
                                                election,
                                            )}/electorates`,
                                            dataDefinitionArgs: {
                                                type: 'election',
                                                electionId: 632,
                                                pageSize: 1, // Throwaway value, required to pass type check ¯\_(ツ)_/¯
                                            },
                                        },
                                    }),
                                    layout.component({
                                        type: 'election-full-electorate-widget',
                                        props: {
                                            dataDefinitionArgs: {
                                                type: 'election',
                                                electionId: 632,
                                                pageSize: 1, // Throwaway value, required to pass type check ¯\_(ツ)_/¯
                                            },
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
