import styled from '@emotion/styled'
import { calcRem, breakpoint } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'

export const LogoContainer = styled('div')(() => ({
    alignSelf: 'center',
    padding: calcRem(16, 24),
    paddingTop: calcRem(24),
    paddingBottom: calcRem(24),
    margin: 0, //remove margin added by H2 wrapping

    [breakpoint('xs')]: {
        alignSelf: 'auto',
        paddingTop: calcRem(41),
        paddingBottom: calcRem(32),
        borderBottom: 'none',
    },
    [breakpoint('lg')]: {
        paddingLeft: 0,
    },
}))

export const DividerContainer = styled('div')(() => ({
    borderBottom: `1px solid ${tokens.thenightly.colors.borders.secondary}`,
    display: 'flex',
    flexDirection: 'column',
    [breakpoint('xs')]: {
        borderBottom: 'none',
    },
}))
