import React, { useCallback, useEffect, useState } from 'react'
import {
    StyledWardenTitle,
    StyledWardenParagraph,
    StyledStaticPageContainer,
    StyledLinkButton,
    emailSentWithErrorNotification,
    emailSentWithSuccessNotification,
    StyledWardenButton,
    StyledWebLinkButton,
} from '../../warden'
import { useLocation } from 'react-router'
import {
    AppState,
    AuthenticationState,
    DataLayerEventName,
    StateTokenCookie,
    authHasOptedInNewsletterState,
    authHasSignedUpNewsletterState,
    urlSafeBase64ToObj,
    NewsletterSignupEvent,
} from '@news-mono/web-common'
import { useDispatch, useSelector } from 'react-redux'
import {
    Container,
    StyledCheckboxGroup,
    StyledGroup,
    StyledSubTitle,
} from './TheNightlySignupSuccess.styled'
import { TNToastComponent } from '../../../content/TNToastNotification/TNToastNotification'
import { AlertType } from '../../../content'
import { useSendVerifyEmail } from '../../../user-setting/hooks/useSendVerifyEmail'
import { UserSettingsCheckbox } from '../../../user-setting/components/UserSettingsCheckbox'
import { metrics } from '../../../__styling'
import {
    OPTED_IN_NEWSLETTER_COOKIE_NAME,
    SIGNED_UP_TO_NEWSLETTER_COOKIE_NAME,
    updateUserMetadata,
} from '../helpers/newsletter-subscription'
import { handleUnknownError } from '@news-mono/common'
import { tokens } from '@news-mono/design-tokens'
import { set as setCookie } from 'js-cookie'

export interface SignupSuccessProps {
    onEvent: (event: NewsletterSignupEvent) => void
}

export const SignupSuccess: React.FC<SignupSuccessProps> = ({
    onEvent,
}: SignupSuccessProps) => {
    const [notifText, setNotifText] = useState('')
    const [notifType, setNotifType] = useState<AlertType>('success')

    const dispatch = useDispatch()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const stateFromQueryParams = queryParams.get('state')
    const { isLoggedIn, wanUserId, auth0UserId, emailVerified, isLoading } =
        useSelector<AppState, AuthenticationState>(
            ({ authentication }) => authentication,
        )

    const setNotification = (text: string, type: AlertType) => {
        setNotifText(text)
        setNotifType(type)
    }

    const { mutateAsync: sendEmail } = useSendVerifyEmail(
        auth0UserId || wanUserId,
    )

    const handleSendVerificationEmail = useCallback(async () => {
        const response = await sendEmail()
        if (response) {
            setNotification(emailSentWithSuccessNotification, 'success')
        } else {
            setNotification(emailSentWithErrorNotification, 'error')
        }
    }, [sendEmail])

    const decodedState = stateFromQueryParams
        ? urlSafeBase64ToObj<StateTokenCookie>(stateFromQueryParams)
        : undefined

    const continueReadingNavigationLink =
        decodedState && decodedState.originUrl ? decodedState.originUrl : '/'

    const [subscribeToNewsletter, setSubscribeToNewsletter] = useState(true)

    const [updatingNewsletterPreferences, setUpdatingNewsletterPreferences] =
        useState(false)

    // fire of an available event, when the page loads and user is logged in
    useEffect(() => {
        if (isLoggedIn) {
            onEvent({
                type: DataLayerEventName.newsletterSignupAvailable,
                originator: 'TNSignupSuccess',
                payload: {
                    newsletterVariant: 'post-registration',
                },
            })
        }
    }, [isLoggedIn, onEvent])

    const updateNewsletterPreferences = async () => {
        try {
            // set the state to ensure that the button is disabled
            setUpdatingNewsletterPreferences(true)

            // set subscribe to newletter to 'true' when the user has not opted in
            // false otherwise
            const newsletterSubscribe = !subscribeToNewsletter

            // update metadata indicating the user has opted in to reveice newsletters
            updateUserMetadata(
                auth0UserId || wanUserId,
                subscribeToNewsletter,
                newsletterSubscribe,
            )

            setCookie(
                OPTED_IN_NEWSLETTER_COOKIE_NAME,
                `${subscribeToNewsletter}`,
                {
                    expires: 1, //days
                },
            )

            // update the Auth state
            dispatch(authHasOptedInNewsletterState(subscribeToNewsletter))

            // set the state and signup cookie and fire an event, if the user has opted out of the newsletter
            if (!subscribeToNewsletter) {
                setCookie(SIGNED_UP_TO_NEWSLETTER_COOKIE_NAME, 'true', {
                    expires: 1, //days
                })

                dispatch(authHasSignedUpNewsletterState(true))
            }
        } catch (error) {
            const err = handleUnknownError(error)
            console.error(err)
            return
        } finally {
            setUpdatingNewsletterPreferences(false)
        }
    }

    return (
        <StyledStaticPageContainer>
            {notifText && (
                <TNToastComponent
                    text={notifText}
                    type={notifType}
                    useMaxWidth={false}
                ></TNToastComponent>
            )}
            <StyledWardenTitle>
                Thanks for being a reader of The&nbsp;Nightly.
            </StyledWardenTitle>
            {!isLoading && (
                <>
                    <StyledSubTitle>
                        {emailVerified ? 'K' : 'Verify now or k'}eep reading.
                    </StyledSubTitle>
                    {emailVerified ? (
                        <StyledWardenParagraph>
                            Continue reading and enjoy full access.
                        </StyledWardenParagraph>
                    ) : (
                        <StyledWardenParagraph>
                            Check your inbox to verify your account for full
                            access like leaving comments or continue reading and
                            verify later.
                        </StyledWardenParagraph>
                    )}
                    {isLoggedIn && !emailVerified && (
                        <StyledLinkButton onClick={handleSendVerificationEmail}>
                            Resend verification email.
                        </StyledLinkButton>
                    )}
                    {isLoggedIn && (
                        <StyledGroup>
                            <StyledSubTitle>
                                Hear about it first.
                            </StyledSubTitle>
                            <p>
                                Get news as it breaks, delivered to your inbox.
                            </p>
                            <StyledCheckboxGroup>
                                <UserSettingsCheckbox
                                    name="subscribe-to-newsletters"
                                    label="I want to receive newsletters."
                                    checked={subscribeToNewsletter}
                                    width={'fit-content'}
                                    size={metrics.thenightly.margins.m2l}
                                    onChange={() => {
                                        setSubscribeToNewsletter(
                                            !subscribeToNewsletter,
                                        )

                                        // fire interaction event
                                        onEvent({
                                            type: DataLayerEventName.newsletterSignupInteract,
                                            originator: 'TNSignupSuccess',
                                            payload: {
                                                newsletterVariant:
                                                    'post-registration',
                                                interactionType: 'opted-in',
                                            },
                                        })
                                    }}
                                />
                            </StyledCheckboxGroup>
                        </StyledGroup>
                    )}
                </>
            )}
            <Container>
                {isLoggedIn ? (
                    <StyledWardenButton
                        roundEdges={true}
                        disabled={updatingNewsletterPreferences}
                        backgroundColors={{
                            default:
                                tokens.thenightly.colors.palette.uplate.default,
                            hover: tokens.thenightly.colors.palette.uplate
                                .hover,
                        }}
                        useWidePadding={true}
                        onClick={async () => {
                            // set subscription preferences for newsletters
                            await updateNewsletterPreferences()

                            // navigate to the original page
                            window.location.href = continueReadingNavigationLink
                        }}
                    >
                        Save and Continue
                    </StyledWardenButton>
                ) : (
                    <StyledWebLinkButton
                        to={continueReadingNavigationLink}
                        forceInternalSSR
                    >
                        Continue Reading
                    </StyledWebLinkButton>
                )}
            </Container>
        </StyledStaticPageContainer>
    )
}
