import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { FederalElection2025Logo } from '../Logo'
import {
    DividerContainer,
    LogoContainer,
} from './FedElection2025HomepageLogo.styled'

export const FedElection2025HomepageLogoRegistration =
    createRegisterableComponent('federal-election-2025-logo', () => {
        return (
            <DividerContainer>
                <LogoContainer as={'h2'}>
                    <div style={{ display: 'none' }}>2025 Federal Election</div>
                    <FederalElection2025Logo size="large" />
                </LogoContainer>
            </DividerContainer>
        )
    })
