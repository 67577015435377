import { AggregatedData, AdTargeting, Features } from '@news-mono/web-common'
import { sectionThemeOverrider } from '@news-mono/component-library'
import { layout } from '../../App.routing'
import { cardList } from './get-cardList-segment'
import { RoutableNightlySection } from '@west-australian-newspapers/publication-types'
import { sectionNavItems } from '../../navigation'
import { CheckedComponentInformation } from 'json-react-layouts'

type TopicSectionParams = {
    /** The section this topic-section is themed after. */
    section: RoutableNightlySection
    /** Defaults to the section. */
    topic?: string
    /** Defaults to the topic. */
    curation?: string
    /** A unique identifier for the adSlots in this section. */
    adPos?: number
    /** Targeting info for the advertisement. */
    adTargeting?: AdTargeting
    /** If the aside advert should be shown. */
    showAsideAdvert?: boolean
    /** Conditional toggle for render of section */
    feature?: Features
    /** Custom ID, usually used for logging purposes */
    debuggingCustomId?: string
}

export const topicSection = ({
    section,
    topic = section,
    curation = topic,
    adPos,
    adTargeting,
    showAsideAdvert = true,
    feature,
    debuggingCustomId,
}: TopicSectionParams) =>
    layout.nestedComposition({
        type: 'scheme-overrider',
        props: {
            override: sectionThemeOverrider('thenightly', section), // Section Colors to be implemented.
        },
        feature: feature,
        contentAreas: {
            children: [
                layout.nestedComposition({
                    type: 'section-content',
                    props: {
                        section,
                        heading: sectionNavItems[section].name,
                        onEvent: () => {},
                    },
                    contentAreas: {
                        children: [
                            layout.component({
                                type: 'section-header',
                                props: {
                                    heading: sectionNavItems[section].name,
                                    headingUrl: sectionNavItems[section].link,
                                },
                            }),
                            layout.nestedComposition({
                                type: 'luna',
                                props: { hasAfterAside: false },
                                contentAreas: {
                                    beforeAside: [
                                        layout.component({
                                            type: 'article-card-list',
                                            props: {
                                                dataDefinitionArgs:
                                                    getCombinedCurationListingData(
                                                        {
                                                            curation: {
                                                                id: curation,
                                                                itemCount: 8,
                                                            },
                                                            topic,
                                                            offset: 0,
                                                            pageSize: 3,
                                                        },
                                                    ),
                                                expectedItemCount: 3,
                                                adTargeting: adTargeting,
                                                debuggingCustomId,
                                            },
                                        }),
                                    ],
                                    aside: [
                                        layout.nestedComposition({
                                            type: 'box',
                                            props: {
                                                horizontalSpacing:
                                                    'outerMargin',
                                                horizontalSpacingBreakpoint:
                                                    'sm',
                                                flex: {
                                                    default: {
                                                        flexGrow: 1,
                                                        flexDirection: 'column',
                                                    },
                                                },
                                            },
                                            contentAreas: {
                                                main: [
                                                    getCombinedCardListCurationData(
                                                        {
                                                            curation: {
                                                                id: curation,
                                                                itemCount: 8,
                                                            },
                                                            topic,
                                                            offset: 3,
                                                            pageSize: 5,
                                                            title: 'Latest',
                                                            sectionText:
                                                                sectionNavItems[
                                                                    section
                                                                ].name,
                                                        },
                                                    ),
                                                    ...(showAsideAdvert
                                                        ? [
                                                              layout.component({
                                                                  type: 'ad-unit',
                                                                  props: {
                                                                      adType: 'inline',
                                                                      noticePosition:
                                                                          'above-center',
                                                                      slot: {
                                                                          id: `${adPos}-mrec-${section}`,
                                                                          size: 'above1280MrecHalfPage',
                                                                      },
                                                                      stickyOffset:
                                                                          'calc(var(--stickyHeaderHeight) + 80px)',
                                                                  },
                                                              }),
                                                          ]
                                                        : []),
                                                ],
                                            },
                                        }),
                                    ],
                                    afterAside: [],
                                },
                            }),
                        ],
                    },
                }),
            ],
        },
    })

export type GetCombinedCardListCurationDataProps = {
    title: string
    sectionText?: string
} & GetCombinedDataProps

export type GetCombinedDataProps = {
    curation: {
        /** The totoa */
        id: string
        itemCount: number
    }
    topic?: string
    offset: number
    pageSize: number
}

export const getCombinedCardListCurationData = ({
    curation,
    topic = curation.id,
    offset,
    pageSize,

    title,
    sectionText,
}: GetCombinedCardListCurationDataProps): CheckedComponentInformation => {
    return cardList(
        getCombinedCurationListingData({
            curation,
            topic,
            offset,
            pageSize,
        }),
        title,
        sectionText,
    )
}

export const getCombinedCurationListingData = ({
    curation,
    topic = curation.id,
    offset,
    pageSize,
}: GetCombinedDataProps): AggregatedData => ({
    type: 'aggregated',
    sources: [
        {
            type: 'curation',
            name: curation.id,
            offset: 0,
            pageSize: curation.itemCount,
        },
        {
            type: 'listing',
            topics: [topic],
            includeSubTopics: true,
            publicationKind: 'galleryAndArticle',
            paging: {
                page: 1,
                pageSize: curation.itemCount, // in the event the curation has 0 entries.
            },
        },
    ],
    offset,
    maxPageSize: pageSize,
})
