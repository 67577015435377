import React, { FC } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useNotificationCentre } from './hooks/useNotificationCentre'
import {
    CollectionEvent,
    NotificationCentreEvent,
    useFeature,
} from '@news-mono/web-common'
import { NotificationBellIcon } from './components/NotificationBellIcon'
import { NotificationCentreModal } from './components/NotificationCentreModal'

interface NotificationCentreProps {
    isLoggedIn: boolean
    onEvent: (event: CollectionEvent | NotificationCentreEvent) => void
}

export const NotificationCentre: FC<NotificationCentreProps> = (props) => {
    const queryClient = new QueryClient()

    return (
        <QueryClientProvider client={queryClient}>
            <InnerNotificationCentre {...props} />
        </QueryClientProvider>
    )
}

const InnerNotificationCentre: FC<NotificationCentreProps> = ({
    isLoggedIn,
    onEvent,
}) => {
    const isFeatureActive = useFeature('notification-centre')
    const {
        notificationCentreListSections,
        hasUnreadNotifications,
        isOpen,
        openModal,
        closeModal,
        modalRef,
        isApp,
    } = useNotificationCentre({ isFeatureActive, onEvent })

    if (!isLoggedIn || !isFeatureActive) return null

    return (
        <>
            <NotificationBellIcon
                hasNewItems={hasUnreadNotifications}
                isApp={isApp}
                onClick={openModal}
            />
            <NotificationCentreModal
                isOpen={isOpen}
                notificationSections={notificationCentreListSections}
                closeModal={closeModal}
                onEvent={onEvent}
                ref={modalRef}
            />
        </>
    )
}
