import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import {
    BaseClientConfig,
    ConfigurationContext,
    DataLayerEventName,
    NotificationCentreEvent,
    Product,
    RenderTargetContext,
} from '@news-mono/web-common'
import fetch from 'node-fetch'
import { Paging } from '../../data-controllers/Pagination/usePagination'
import { useQuery } from '@tanstack/react-query'
import {
    NotificationCentreItemListV4ResponseDTO,
    NotificationCentreItemV4DTO,
} from '@west-australian-newspapers/publication-types'
import { useLocalStorage } from '../../../../web-common/src/hooks/useLocalStorage'
import { useProduct } from '@news-mono/component-library'
import { NotificationCentreListSection } from '../types'
import { buildNotificationList } from './helpers/build-notification-list'
import {
    useNotificationCentreModal,
    UseNotificationCentreModalResult,
} from './useNotificationCentreModal'

// Static for now.
export const paginationProps: Paging = {
    page: 1,
    pageSize: 30,
    pageOffset: 0,
}

// A date that precedes the notifications
const defaultDateIso = new Date('2024-01-01').toISOString()
// How often we should refetch the data in milliseconds
const refetchInterval = 300 * 1000 // 5 Minutes

const getLocalStorageKey = (product: Product) => {
    return `SWM-${product}-Notification-Centre-Last-Viewed`
}

interface UseNotificationCentreProps {
    isFeatureActive: boolean
    onEvent: (event: NotificationCentreEvent) => void
}

interface UseNotificationCentreResult extends UseNotificationCentreModalResult {
    notificationCentreListSections: NotificationCentreListSection[]
    isLoading: boolean
    hasUnreadNotifications: boolean
    isApp: boolean
}

export const useNotificationCentre = ({
    isFeatureActive,
    onEvent,
}: UseNotificationCentreProps): UseNotificationCentreResult => {
    const config = useContext(ConfigurationContext)
    const { renderTarget } = useContext(RenderTargetContext)

    const product = useProduct()

    const [notificationCentreListSections, setNotificationCentreListSections] =
        useState<NotificationCentreListSection[]>([])
    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false)

    const lastUpdatedTime = useRef(0)

    const [lastViewedDateIso, setLastViewedDateIso] = useLocalStorage({
        key: getLocalStorageKey(product),
        initialValue: defaultDateIso,
    })

    const openModalCallback = useCallback(() => {
        if (notificationCentreListSections[0]?.notifications.length > 0) {
            setLastViewedDateIso(
                new Date(
                    notificationCentreListSections[0]?.notifications[0].dateSent,
                ).toISOString(),
            )
        }

        // Prevent scrolling outside the modal
        document.body.style.overflow = 'hidden'

        onEvent({
            type: DataLayerEventName.notificationCentreOpened,
            originator: 'NotificationCentre',
            payload: {
                hasUnreadNotifications,
            },
        })
    }, [
        hasUnreadNotifications,
        notificationCentreListSections,
        onEvent,
        setLastViewedDateIso,
    ])

    const closeModalCallback = useCallback(() => {
        setHasUnreadNotifications(false)

        // Re-allow scrolling outside the modal
        document.body.style.overflow = 'auto'

        onEvent({
            type: DataLayerEventName.notificationCentreClosed,
            originator: 'NotificationCentre',
            payload: {},
        })
    }, [onEvent])

    const { isOpen, openModal, closeModal, modalRef } =
        useNotificationCentreModal({ openModalCallback, closeModalCallback })

    const { data, isLoading, dataUpdatedAt } = useQuery({
        queryKey: ['notification-centre'],
        queryFn: async () =>
            await fetchNotificationsSince(
                config,
                defaultDateIso,
                paginationProps,
            ),

        enabled: isFeatureActive,
        refetchInterval: refetchInterval,
        refetchIntervalInBackground: true,
        staleTime: refetchInterval,
    })

    if (
        data?.notificationCentreItems &&
        data.notificationCentreItems.length > 0 &&
        lastUpdatedTime.current < dataUpdatedAt
    ) {
        setNotificationCentreListSections(
            buildNotificationList(data.notificationCentreItems, new Date()),
        )

        setHasUnreadNotifications(
            responseHasUnreadNotifications(
                data.notificationCentreItems,
                lastViewedDateIso,
            ),
        )

        lastUpdatedTime.current = dataUpdatedAt
    }

    return useMemo(
        () => ({
            notificationCentreListSections,
            isLoading,
            hasUnreadNotifications,
            isOpen,
            closeModal,
            modalRef,
            openModal,
            isApp: renderTarget === 'app',
        }),
        [
            notificationCentreListSections,
            isLoading,
            hasUnreadNotifications,
            isOpen,
            closeModal,
            modalRef,
            openModal,
            renderTarget,
        ],
    )
}

export const fetchNotificationsSince = async (
    config: BaseClientConfig,
    dateIso: string,
    pagination: Paging,
): Promise<NotificationCentreItemListV4ResponseDTO> => {
    const dateParam = `dateIso=${dateIso}`
    const pageParam = `page=${pagination.page}`
    const pageSizeParam = `page_size=${pagination.pageSize}`
    const pageOffsetParam =
        pagination.pageOffset !== undefined
            ? `&page_offset=${pagination.pageOffset}`
            : ''

    const url = `${config.contentApi}/v4/notifications/notification-centre/since?${dateParam}&${pageParam}&${pageSizeParam}${pageOffsetParam}`
    const requestConfig = getRequestConfig(config)

    return await fetch(url, requestConfig).then((response) => response.json())
}

const getRequestConfig = (config: BaseClientConfig) => {
    return {
        headers: {
            caller: config.apiCallerHeader,
        },
    }
}

const responseHasUnreadNotifications = (
    notifications: NotificationCentreItemV4DTO[],
    lastViewedDateIso: string,
) => {
    return notifications.some(
        (notification) => notification.dateSent > lastViewedDateIso,
    )
}
