import { TheNightlySection } from '@news-mono/common'
import { sectionThemeOverrider, metrics } from '@news-mono/component-library'
import { GetRouteAdTargeting, PageType } from '@news-mono/web-common'
import { layout } from '../../../App.routing'

type CreateElectorateEmbedPageArgs = {
    heading: string
    description: string
    getAdTargeting: GetRouteAdTargeting
}

export const createElectorateEmbedPage = ({
    heading,
    description,
    getAdTargeting,
}: CreateElectorateEmbedPageArgs): PageType<TheNightlySection> => {
    return {
        kind: 'page',
        heading,
        pageType: 'static',
        section: 'politics',
        hideHeading: true,
        adTargeting: getAdTargeting('home', 'default', 'home'),
        pageMeta: {
            description,
        },
        socialMeta: {
            description,
        },
        compositions: [
            layout.composition({
                type: 'scheme-overrider',
                props: {
                    override: sectionThemeOverrider('thenightly', 'politics'),
                },
                contentAreas: {
                    children: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                containerWidth:
                                    metrics.thenightly.siteMetrics
                                        .mainContentWidth,
                                fullWidth: true,
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'election-seat-embed-widget',
                                        props: {
                                            dataDefinitionArgs: {
                                                type: 'election',
                                                electionId: 632,
                                                pageSize: 1,
                                            },
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
