import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    TNElectionNavigation,
    TNElectionNavigationProps,
} from './TNElectionNavigation'

export const TNElectionNavigationRegistration = createRegisterableComponent(
    'election-navigation',
    (props: TNElectionNavigationProps, { onEvent }) => {
        return <TNElectionNavigation onEvent={onEvent} {...props} />
    },
)
