import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheNightlySection } from '@news-mono/common'
import {
    ElectionDefinition,
    getElectionId,
    getElectionToggles,
    getResolvedTopicPageMeta,
    StaticRoutes,
} from '@news-mono/web-common'
import { createElectorateEmbedPage } from '../embeds/createElectorateEmbedPage'
import { createElectionLandingPage } from './createElectionLandingPage'

/**
 * Creates the routes declarations for an election.
 */
export const createElectionRoutes = (
    electionDefinition: ElectionDefinition,
): StaticRoutes<TheNightlySection> => {
    return {
        [`/politics/${getElectionId(electionDefinition.election)}`]: ({
            getAdTargeting,
            store,
            resolution,
        }) => {
            // Override meta with that from taxonomy if provided.
            const topicMeta = getResolvedTopicPageMeta(resolution)

            const toggles = store.getState().toggles

            const { 'election-landing-page-route': isLandingPageEnabled } =
                getElectionToggles(electionDefinition.election, toggles)

            if (!isLandingPageEnabled) {
                return null
            }

            return createElectionLandingPage({
                getAdTargeting,
                electionDefinition,
                toggles,
                heading: topicMeta?.seoTitle,
                description: topicMeta?.seoDescription,
            })
        },
        [`/embeds/${getElectionId(electionDefinition.election)}/electorate`]: ({
            getAdTargeting,
            store,
        }) => {
            const enableRoute = isFeatureEnabled(
                toFeatureState(store.getState().toggles),
                'election-widget-preview-page',
            )

            if (!enableRoute) {
                return null
            }

            let heading
            let description

            if (electionDefinition.election.type === 'federal') {
                heading = `${electionDefinition.election.year} Federal Election Embed`
                description = 'An embed for the federal election electorate.'
            } else {
                heading = `${
                    electionDefinition.election.year
                } ${electionDefinition.election.state.toUpperCase()} State Election Embed`
                description = 'An embed for the state election electorate.'
            }

            return createElectorateEmbedPage({
                heading,
                description,
                getAdTargeting,
            })
        },
    }
}
